import GeoJSON from "geojson";
import React from "react";
import {CircularProgress, Stack, Typography} from "@mui/material";
import CroppedImage from "../create/select/components/CroppedImage";
import type {ImplementProgramFeature} from "react_ct/types";
import {useQuery} from "@tanstack/react-query";
import {apiGetRotatedLabelledImage} from "react_ct/requests";

export function getRawFeature(
    rawFeatures: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>>,
    selectedFeature: ImplementProgramFeature | undefined,
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> | undefined {
    if (!selectedFeature || !rawFeatures) {
        return undefined;
    }
    return rawFeatures.find(
        feature =>
            feature.geometry.coordinates[0] === selectedFeature.gpsCoordinate[0] &&
            feature.geometry.coordinates[1] === selectedFeature.gpsCoordinate[1],
    );
}

export function FeatureImageContainer({
    feature,
    imageContainerRef,
}: {
    feature: GeoJSON.Feature | undefined;
    imageContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}): React.ReactElement {
    const {
        data: rotatedLabelledImage,
        isPending: isRotatedImagePending,
        error: rotatedImageError,
    } = useQuery({
        queryKey: ["rotatedLabelledImage", feature?.properties?.id],
        enabled: !!feature?.properties,
        queryFn: async () => {
            const res = await apiGetRotatedLabelledImage(feature?.properties?.scan_id);
            if (res.rotatedLabelledImageUrl) {
                return res.rotatedLabelledImageUrl;
            } else {
                throw new Error("Could not find rotated labelled image url");
            }
        },
    });
    if (!feature) {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                sx={{backgroundColor: theme => theme.palette.grey[200]}}>
                <Typography variant="h4" color={theme => theme.palette.grey[500]}>
                    No image available
                </Typography>
            </Stack>
        );
    }

    if (feature.properties?.fpv_media) {
        return <img src={feature.properties?.fpv_media} alt={feature.properties?.address} width="100%" height="100%" />;
    } else if (feature.properties?.centroid && feature.properties?.labelled_media_link) {
        if (rotatedLabelledImage) {
            return (
                <CroppedImage
                    url={rotatedLabelledImage}
                    centroid={feature.properties.centroid}
                    containerRef={imageContainerRef}
                />
            );
        } else if (isRotatedImagePending) {
            return <CircularProgress />;
        } else {
            return (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    sx={{backgroundColor: theme => theme.palette.grey[200]}}>
                    <Typography variant="h4" color={theme => theme.palette.grey[500]}>
                        No image available
                    </Typography>
                </Stack>
            );
        }
    } else if (!feature.properties?.centroid && feature.properties?.cropped_media) {
        return (
            <img src={feature.properties?.cropped_media} alt={feature.properties?.address} width="100%" height="100%" />
        );
    } else {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                sx={{backgroundColor: theme => theme.palette.grey[200]}}>
                <Typography variant="h4" color={theme => theme.palette.grey[500]}>
                    No image available
                </Typography>
            </Stack>
        );
    }
}
