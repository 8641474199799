import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack, ToggleButton, ToggleButtonGroup, darken, lighten, toggleButtonClasses} from "@mui/material";
import {
    accessibilityProps,
    censusDataProps,
    clearWidthProps,
    crossSlopePanelProps,
    crossSlopeProps,
    deteriorationProps,
    obstructionDataProps,
    runSlopeProps,
} from "./helpers";
import {VisibilityOff} from "@mui/icons-material";

function LegendItem(props: {
    layerKey: string;
    name: string;
    values: number[];
    gradient: Array<{color: string; width?: number}>;
    selectedValues: Array<{key: string; filterValues: number[]}>;
    setSelectedValues: React.Dispatch<React.SetStateAction<Array<{key: string; filterValues: number[]}>>>;
    unit?: string;
}): React.ReactElement {
    const {layerKey, name, values, gradient, unit, selectedValues, setSelectedValues} = props;

    return (
        <Box width="100%" mt={layerKey === "clear_width" ? 0 : 1}>
            <Typography fontWeight={700} fontSize="0.9rem" color="rgba(0, 0, 0, 0.54)">
                {name}
            </Typography>
            <ToggleButtonGroup
                sx={{width: "100%", height: "1em", mt: 1}}
                value={selectedValues
                    .find(value => value.key === layerKey)
                    ?.filterValues.map(value => `${layerKey}_${value}`)}
                onChange={(event: React.MouseEvent<HTMLElement>, newValues: string[]) => {
                    setSelectedValues(prev => {
                        const legendValues = prev.find(value => value.key === layerKey);
                        const valueNums = newValues.map(val => parseInt(val.split("_")[1]));
                        if (!legendValues) {
                            return [...prev, {key: layerKey, filterValues: valueNums}];
                        } else {
                            legendValues.filterValues = newValues.map(val => parseInt(val.split("_")[1]));
                            const newArray = prev.filter(value => value.key !== layerKey);
                            newArray.push(legendValues);
                            return newArray;
                        }
                    });
                }}>
                {gradient.map((value, index) => {
                    const buttonValue =
                        index >= values.length
                            ? `${layerKey}_${values[values.length - 1] + 1}`
                            : `${layerKey}_${values[index]}`;

                    return (
                        <ToggleButton
                            key={index}
                            value={buttonValue}
                            sx={{
                                flexBasis: value.width ? `${value.width}%` : undefined,
                                flexGrow: value.width ? undefined : 1,
                                backgroundColor: darken(value.color, 0.4),
                                border: "none",
                                borderLeft: "none",
                                boxShadow: "inset 0px 2px 5px #00000022",
                                "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                                    borderLeft: "none",
                                },
                                "&:hover": {
                                    backgroundColor: value.color,
                                },
                                [`&.${toggleButtonClasses.selected}`]: {
                                    boxShadow: "none",
                                    backgroundColor: value.color,
                                },
                                [`&.${toggleButtonClasses.selected}:hover`]: {
                                    backgroundColor: lighten(value.color, 0.2),
                                },
                            }}>
                            {!selectedValues.find(val =>
                                val.filterValues.includes(parseInt(buttonValue.split("_")[1])),
                            ) && <VisibilityOff sx={{fontSize: "1rem", color: "#fff"}} />}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            {values && (
                <Stack
                    direction="row"
                    width="100%"
                    height="0.8rem"
                    mt={-1}
                    ml={name.toLowerCase() !== "sidewalk accessibility" ? 3 : undefined}>
                    {values.map((value, index) => {
                        if (
                            (unit && index === values.length - 1 && name.toLowerCase() !== "percent deteriorated") ||
                            (name.toLowerCase() === "cross slope score" && index === values.length - 1)
                        ) {
                            return <></>;
                        }
                        return (
                            <Box
                                key={value}
                                flexBasis={gradient[index].width ? `${gradient[index].width ?? 0}%}` : undefined}
                                flexGrow={gradient[index].width ? undefined : 1}>
                                <Typography fontSize="0.8rem" textAlign="center" color="#00000099">
                                    {`${value}${unit ? `${unit}` : ""}`}
                                </Typography>
                            </Box>
                        );
                    })}
                </Stack>
            )}
        </Box>
    );
}

export function FeatureLegend({
    currentFeatures,
    selectedValues,
    setSelectedValues,
}: {
    currentFeatures: string[];
    selectedValues: Array<{key: string; filterValues: number[]}>;
    setSelectedValues: React.Dispatch<React.SetStateAction<Array<{key: string; filterValues: number[]}>>>;
}): React.ReactElement {
    return currentFeatures.length > 0 ? (
        <Box
            id="feature-legend"
            width="20vw"
            px={4}
            py={1}
            borderRadius={1}
            sx={theme => ({
                backgroundColor: theme.palette.background.paper,
                backdropFilter: "blur(10px)",
                boxShadow: theme.shadows[3],
                borderRadius: theme.shape.borderRadius,
            })}>
            {currentFeatures.includes("Clear Width") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...clearWidthProps} />
            )}
            {currentFeatures.includes("Run Slope") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...runSlopeProps} />
            )}
            {currentFeatures.includes("Cross Slope") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...crossSlopeProps} />
            )}
            {(currentFeatures.includes("Midblock Accessibility") || currentFeatures.includes("Curb Ramp")) && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...accessibilityProps} />
            )}
            {currentFeatures.includes("Deterioration Layer") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...deteriorationProps} />
            )}
            {currentFeatures.includes("Census") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...censusDataProps} />
            )}
            {currentFeatures.includes("Obstructions") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...obstructionDataProps} />
            )}
            {currentFeatures.includes("Cross Slope Panel") && (
                <LegendItem {...{selectedValues, setSelectedValues}} {...crossSlopePanelProps} />
            )}
        </Box>
    ) : (
        <></>
    );
}
