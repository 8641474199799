import {AxiosResponse} from "axios";
import {z} from "zod";

import {apiRequest} from ".";
import {shouldUseBetaEnv} from "../utils";

const geojsonZippedSchema = z.object({
    geojsonUrl: z.string().optional(),
    individualFeaturesUrl: z.string().optional(),
    obstructionsUrl: z.string().optional(),
    lastModified: z.string().optional(),
});

export type GeojsonZipType = z.infer<typeof geojsonZippedSchema>;

export const apiGetGeojsonZip = async (
    projectId: number,
    retrieveTypes: {
        geojson?: boolean;
        obstructions?: boolean;
        indvfeatures?: boolean;
        arcgis?: boolean;
    } = {
        geojson: true,
        obstructions: true,
        indvfeatures: true,
        arcgis: true,
    },
): Promise<GeojsonZipType> => {
    const beta = shouldUseBetaEnv() ? "true" : "false";

    const {data} = await apiRequest({
        path: `project/${projectId}/geojson/zip?beta=${beta}`,
        params: {
            projectId,
            geojson: retrieveTypes.geojson,
            arcgis: retrieveTypes.arcgis,
            obstructions: retrieveTypes.obstructions,
            indvfeatures: retrieveTypes.indvfeatures,
        },
    });

    const parsedReponse = geojsonZippedSchema.safeParse(data);
    if (!parsedReponse.success) {
        return data as GeojsonZipType;
    }
    return parsedReponse.data;
};

const geojsonRawSchema = z.object({
    geojsonUrl: z.string(),
    lastModified: z.string().optional(),
});

export const apiGetGeojsonUrl = async (projectId: number, type?: string): Promise<string> => {
    const beta = shouldUseBetaEnv() ? "true" : "false";

    let url = "";
    if (type) {
        url = `project/${projectId}/geojson/${type}?beta=${beta}`;
    } else {
        url = `project/${projectId}/geojson?beta=${beta}`;
    }
    const {data} = await apiRequest({
        path: url,
    });

    const parsedResponse = geojsonRawSchema.safeParse(data);

    if (!parsedResponse.success) {
        return data.geojsonUrl as string;
    }

    return parsedResponse.data.geojsonUrl;
};

export const generateGeojson = async (projectId: number): Promise<AxiosResponse> => {
    const beta = shouldUseBetaEnv() ? "true" : "false";

    const response = await apiRequest({
        path: `project/${projectId}/geojson?beta=${beta}`,
        method: "POST",
    });

    return response.status === 200 ? response : await Promise.reject(response);
};
