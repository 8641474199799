import {z} from "zod";
import {ProjectUser} from ".";

export enum Features {
    tree_hazard = "Tree Hazard",
    overhang = "Overhang",
    utility_box = "Utility Box",
    vertical = "Vertical",
    horizontal = "Horizontal",
    driveway = "Driveway",
    deterioration = "Deterioration",
}

export type FeatureType = `${Features}`;

export enum ViolationSeverity {
    extreme = "extreme",
    severe = "severe",
    moderate = "moderate",
    minor = "minor",
}

export type SeverityType = `${ViolationSeverity}`;

export type GeoJSONLayerShapeType = "Point" | "LineString" | "Symbol" | "Polygon";

export const violationsKeySchema = z.union([
    z.literal("extreme"),
    z.literal("severe"),
    z.literal("moderate"),
    z.literal("minor"),
]);

export const newViolationsSchema = z.object({
    cross_slope: violationsKeySchema,
    clear_width: violationsKeySchema,
    run_slope: violationsKeySchema,
});

export const measurementsSchema = z.object({
    display_name: z.string(),
    modifiable: z.boolean(),
    type: z.string(),
    unit: z.string(),
    value: z.number(),
});

export const exceptionsSchema = z.object({
    perpendicular_ramp_run_slope_exception: z.boolean().optional(),
    traffic_signal_ramp_cross_slope_exception: z.boolean().nullable(),
    uncontrolled_ramp_cross_slope_exception: z.boolean().nullable(),
    compliant_grade_break_direction: z.boolean().nullable(),
});

export const evalRegionSchema = z.object({
    area: z.number(),
    centroid: z.array(z.number()).length(2),
    exceptions: exceptionsSchema.optional(),
    flags: z.null().optional(),
    global_region_id: z.number(),
    id: z.string(),
    gps_point: z.array(z.number()).length(2),
    has_cracks: z.boolean().optional(),
    has_deterioration: z.boolean().optional(),
    has_driveway: z.boolean().optional(),
    label: z.string(),
    landing: z.boolean().optional(),
    points: z.array(z.array(z.number()).length(2)),
    shape_type: z.union([z.literal("polygon"), z.literal("linestrip"), z.literal("point")]),
    distance_from_back_of_curb: z.number().optional(),
    compliant_grade_break_direction: z.boolean().optional(),
    ramp_run_length: z.number().optional(),
    ramp_width: z.number().optional(),
    measurements: z
        .object({
            slope: measurementsSchema.optional(),
            area: measurementsSchema.optional(),
            crossslope: measurementsSchema.optional(),
            runslope: measurementsSchema.optional(),
            width: measurementsSchema.optional(),
        })
        .optional(),
    violations: newViolationsSchema,
});

export type EvalRegion = z.infer<typeof evalRegionSchema>;

const aggregatedRampRegionSchema = z.object({
    aggregated_region_type: z.literal("ramp"),
    exceptions: exceptionsSchema.optional(),
    id: z.number(),
    landing_points: z.array(z.array(z.number()).length(2)),
    landings: z.array(z.number()),
    measurements: z.object({
        ramp_run_length: measurementsSchema,
        ramp_width: measurementsSchema,
    }),
    parallel_ramp: z.boolean(),
    points: z.array(z.array(z.number()).length(2)),
    ramp_panels: z.array(z.array(z.number()).length(2)),
});

export type AggregatedRampRegion = z.infer<typeof aggregatedRampRegionSchema>;

const aggregatedLandingRegionSchema = z.object({
    aggregated_region_type: z.literal("landing"),
    id: z.number(),
    landing_regions: z.array(z.number()),
    measurements: z.object({
        turning_width: measurementsSchema,
        slope: measurementsSchema,
    }),
    points: z.array(z.array(z.number()).length(2)),
});

export type AggregatedLandingRegion = z.infer<typeof aggregatedLandingRegionSchema>;

const aggregatedMidblockRegionSchema = z.object({
    aggregated_region_type: z.literal("midblock"),
    id: z.number(),
    midblock_panels: z.array(z.number()),
    points: z.array(z.array(z.number()).length(2)),
});

export type AggregatedMidblockRegion = z.infer<typeof aggregatedMidblockRegionSchema>;

export const aggregatedRegionSchema = z.union([
    aggregatedRampRegionSchema,
    aggregatedLandingRegionSchema,
    aggregatedMidblockRegionSchema,
]);

export type AggregatedRegion = z.infer<typeof aggregatedRegionSchema>;

export const panelSchema = z.object({
    panel_id: z.number(),
    global_region_id: z.number(),
    label: z.number(),
    runslope: z.number(),
    crossslope: z.number(),
    width: z.number(),
    area: z.number(),
    centroid: z.tuple([z.number(), z.number()]),
    violations: z.object({
        clear_width: z.nativeEnum(ViolationSeverity).optional(),
        cross_slope: z.nativeEnum(ViolationSeverity).optional(),
        run_slope: z.nativeEnum(ViolationSeverity).optional(),
    }),
});

export type Panel = z.infer<typeof panelSchema>;

const violationCountSchema = z.object({
    extreme: z.number(),
    severe: z.number(),
    moderate: z.number(),
    minor: z.number(),
    SENTINEL: z.number(),
});

export type ViolationCount = z.infer<typeof violationCountSchema>;

export const violationsSchema = z.object({
    cross_slope: violationCountSchema,
    run_slope: violationCountSchema,
    clear_width: violationCountSchema,
    vert_disps: z.object({
        SENTINEL: z.number(),
        manual: z.number(),
    }),
});

export type Violations = z.infer<typeof violationsSchema>;

export const rawScoreSchema = z.object({
    cs_score: z.number(),
    rs_score: z.number(),
    width_score: z.number(),
    dw_score: z.number(),
    percent_det: z.number(),
    ramp_check: z.boolean(),
});

export type RawScore = z.infer<typeof rawScoreSchema>;

export const mediaLinksSchema = z.object({
    labelled_img: z.string(),
    raster_downsampled: z.string(),
    AutoReport: z.string(),
    pointcloud_las: z.string(),
});

export type MediaLinks = z.infer<typeof mediaLinksSchema>;

export type MapFeature = ReportType | MapScan;

export const jsonStringMetadataSchema = z.object({
    id: z.number(),
    collector: z.number(),
    date: z.string(),
    location: z.tuple([z.number(), z.number()]),
    length: z.number(),
    scale: z.number(),
});

export type JsonStringMetadata = z.infer<typeof jsonStringMetadataSchema>;

export const jsonStringSchema = z.object({
    metadata: jsonStringMetadataSchema,
    images: z.object({
        orthoimage: z.string(),
        label_image: z.string().nullable(),
    }),
    vert_disps: z.array(z.unknown()),
    panels: z.string(),
    dw: z.string(),
    noRamp: z.boolean(),
    mileage_path: z.array(z.array(z.array(z.number()))),
    scale_factor: z.number(),
    regions: z.string(),
    areas: z.object({
        Deteriorated: z.number(),
        Panel: z.number(),
        DW: z.number(),
        Brick: z.number(),
        Driveway: z.number(),
        Gutter: z.number(),
        Asphalt: z.number(),
        Gravel: z.number(),
        Overgrown: z.number(),
        Cracked: z.number(),
        Obstruction: z.number(),
        Stairs: z.number(),
        combined: z.number(),
    }),
    violations: violationsSchema,
    raw_scores: rawScoreSchema,
    access_score: z.number(),
    condition_score: z.number(),
    version: z.string(),
    media_links: mediaLinksSchema,
    gpsMultiline: z.array(z.array(z.number())),
    stairs: z.boolean(),
    access_scores: z.object({
        accessibility_grade: z.number(),
        fix_widths: z.number(),
        fix_vd: z.number(),
        fix_dw: z.number(),
        fix_fruit: z.number(),
    }),
});

export type JsonString = z.infer<typeof jsonStringSchema>;

export const autoReportTypeSchema = z.object({
    metadata: z.object({
        id: z.number(),
        collector: z.number(),
        date: z.string(),
    }),
    images: z.object({
        orthoimage: z.string(),
        label_image: z.string().nullable(),
    }),
    vert_disps: z.array(z.unknown()),
    panels: z.string(),
    dw: z.string(),
    noRamp: z.boolean(),
    mileage_path: z.array(z.array(z.array(z.number()))),
    scale_factor: z.number(),
    regions: z.string(),
    areas: z.object({
        Deteriorated: z.number(),
        Panel: z.number(),
        DW: z.number(),
        Brick: z.number(),
        Driveway: z.number(),
        Gutter: z.number(),
        Asphalt: z.number(),
        Gravel: z.number(),
        Overgrown: z.number(),
        Cracked: z.number(),
        Obstruction: z.number(),
        Stairs: z.number(),
        combined: z.number(),
    }),
    violations: violationsSchema,
    raw_scores: rawScoreSchema,
    access_score: z.number(),
    condition_score: z.number(),
    version: z.string(),
    media_links: mediaLinksSchema,
    gpsMultiline: z.array(z.array(z.number())),
    stairs: z.boolean(),
    access_scores: z.object({
        accessibility_grade: z.number(),
        fix_widths: z.number(),
        fix_vd: z.number(),
        fix_dw: z.number(),
        fix_fruit: z.number(),
    }),
    raster_media: z.string(),
});

export type AutoReportType = z.infer<typeof autoReportTypeSchema>;

export const autoReportMediaSchema = z.object({
    labelled_img: z.string(),
    raster_downsampled: z.string(),
    AutoReport: z.string(),
    pointcloud_las: z.string(),
});

export type AutoReportMedia = z.infer<typeof autoReportMediaSchema>;

export const reportTypeSchema = z.object({
    access_description: z.string(),
    accessibility_grade: z.number().gte(0).lte(5),
    aggregated_regions: z.array(aggregatedRegionSchema),
    areas: z.object({
        Asphalt: z.number(),
        Brick: z.number(),
        Cracked: z.number(),
        Cracked_Panel: z.number(),
        DW: z.number(),
        Deteriorated: z.number(),
        Deteriorated_Panel: z.number(),
        Driveway: z.number(),
        Gravel: z.number(),
        Gutter: z.number(),
        Obstruction: z.number(),
        Overgrown: z.number(),
        Panel: z.number(),
        Stairs: z.number(),
        Total_Deteriorated_Area: z.number(),
        combined: z.number(),
    }),
    compliant: z.boolean(),
    detectable_warning: z.string(),
    fixes: z.object({
        dw_fix: z.number(),
        fruit_fix: z.number(),
        vd_fix: z.number(),
        width_fix: z.number(),
    }),
    material: z.string(),
    measurement_averages: z.object({
        cross_slope: z.number().nullable(),
        run_slope: z.number().nullable(),
        width: z.number().nullable(),
    }),
    measurement_minimums: z.object({
        cross_slope: z.number().nullable(),
        run_slope: z.number().nullable(),
        width: z.number().nullable(),
    }),
    measurement_maximums: z.object({
        cross_slope: z.number().nullable(),
        run_slope: z.number().nullable(),
        width: z.number().nullable(),
    }),
    media_links: z.object({
        autoreport: z.string(),
        labelled_img: z.string(),
        pointcloud_las: z.string(),
        raster_downsampled: z.string(),
    }),
    metadata: z.object({
        collector: z.number(),
        date: z.string(),
        folder: z.string(),
        id: z.number(),
        length: z.number(),
        location: z.tuple([z.number(), z.number()]),
        scale: z.number(),
    }),
    passing_space: z.boolean(),
    percent_deteriorated: z.number(),
    raw_score: z.object({
        cross_slope: z.number().nullable(),
        detectable_warning: z.number().nullable(),
        grade_brake_direction: z.number().nullable(),
        run_slope: z.number().nullable(),
        vertical_displacement: z.number().nullable(),
        width: z.number().nullable(),
    }),
    ramp_check: z.boolean(),
    no_ramp: z.boolean(),
    regions: z.array(evalRegionSchema),
    scan_subtype: z.string(),
    scan_type: z.string(),
    stairs: z.boolean(),
    violation_count: z.object({
        clear_width: z.number(),
        vertical_displacements: z.number(),
        cross_slope: z.number(),
        extreme_obstructions: z.number(),
        run_slope: z.number(),
        severe_obstructions: z.number(),
    }),
    violations: violationsSchema,
});

export const panelFeatureSchema = z.object({
    centroid: z.array(z.number()).length(2),
    max_severity: z.string(),
    scan_id: z.number(),
    type: z.string(),
});

export type PanelFeature = z.infer<typeof panelFeatureSchema>;

export type IndividualFeatureProperties = z.infer<typeof indvFeatureSchema>;

export const indvFeatureSchema = z.object({
    id: z.string(),
    type: z.string(),
    labelled_img_media: z.string().url(),
    raster_media: z.string().url(),
    cropped_media: z.string().url(),
    folder: z.number(),
    scan_id: z.number(),
    feature_name: z.string(),
    google_maps: z.string().url(),
    address: z.string().optional(),
    color: z.string().optional(),
    fpv_media: z.string().optional(),
});

export type ReportType = z.infer<typeof reportTypeSchema>;

export const programFeatureSchema = z.object({
    id: z.number(),
    address: z.string().optional(),
    gpsCoordinate: z.array(z.number()).length(2),
    featureType: z.string(),
    severity: z.string(),
    scanId: z.number(),
    reviewed: z.boolean().optional(),
    accepted: z.boolean().optional(),
    fpv_media: z.string().optional(),
    labelled_img_media: z.string().optional(),
    Lat: z.number().optional(),
    Long: z.number().optional(),
});

export type ImplementProgramFeature = z.infer<typeof programFeatureSchema>;

export const implementProgramSchema = z.object({
    id: z.number(),
    name: z.string(),
    programType: z.string(),
    features: z.array(programFeatureSchema),
    isDraft: z.boolean().optional(),
});

export type ImplementProgram = z.infer<typeof implementProgramSchema>;

export const indvPanelFeatureSchema = z.object({
    area: z.number(),
    centroid: z.array(z.number()).length(2),
    crossslope: z.number(),
    flags: z.array(z.string()),
    global_region_id: z.number(),
    has_cracks: z.boolean(),
    has_deterioration: z.boolean(),
    has_driveway: z.boolean(),
    label: z.string(),
    points: z.array(z.array(z.number()).length(2)),
    runslope: z.number(),
    violations: z.object({
        clear_width: z.string().optional(),
        cross_slope: z.string().optional(),
        run_slope: z.string().optional(),
    }),
    width: z.number(),
});

export type IndividualPanelFeature = z.infer<typeof indvPanelFeatureSchema>;

export const mapScanSchema = z.object({
    id: z.number(),
    scannerId: z.number(), // userId
    lat: z.number(),
    lng: z.number(),
    folderName: z.string(),
    createdAt: z.number(), // epoch
    stage: z.string(),
    scanLength: z.number().nullable(),
    estimatedScanLength: z.number().nullable(),
    gpsMultiline: z.array(z.array(z.number())).nullable(),
    adjustedGpsMultiline: z.array(z.array(z.number())).nullable(),
    gpsCoordinates: z.any().nullable(),
});

export type MapScan = z.infer<typeof mapScanSchema>;
export type CleanMapScan = Omit<Omit<MapScan, "estimatedScanLength">, "scanLength"> & {
    scanLength: number;
    scanLengthConfirmed: boolean;
};

export const evalPanelSchema = z.object({
    global_region_id: z.number(),
    label: z.number(),
    centroid: z.array(z.number()).length(2),
    runslope: z.number(),
    crossslope: z.number(),
    width: z.number(),
    violations: z.object({
        run_slope: z.string().optional(),
        cross_slope: z.string().optional(),
        clear_width: z.string().optional(),
    }),
    landing: z.boolean(),
    exceptions: z
        .object({
            perpendicular_ramp_run_slope_exception: z.boolean().optional(),
            uncontrolled_ramp_cross_slope_exception: z.boolean().optional(),
            traffic_signal_ramp_cross_slope_exception: z.boolean().optional(),
        })
        .nullable(),
});

export type EvalPanel = z.infer<typeof evalPanelSchema>;

export const cleanScanSchema = z.object({
    id: z.number(),
    userId: z.number(),
    userEmail: z.string(),
    folderName: z.string(),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    gpsCoordinates: z.string().nullable(),
    robustGpsCoordinates: z.string().nullable(),
    gpsMultiline: z.array(z.array(z.number())).nullable(),
    adjustedGpsMultiline: z.array(z.array(z.number())).nullable(),
    stage: z.string(),
    scanLength: z.number(),
    scanLengthConfirmed: z.boolean(),
});

export type CleanScan = z.infer<typeof cleanScanSchema>;

export const evalJsonSchema = z.object({
    access_description: z.string(),
    access_scores: z.object({
        accessibility_grade: z.number(),
        fix_widths: z.number(),
        fix_vd: z.number(),
        fix_dw: z.number(),
        fix_fruit: z.number(),
        condition_score: z.number(),
    }),
    accessibility_grade: z.number(),
    adjustedGpsMultiline: z.array(z.array(z.number()).length(2)),
    aggregated_regions: z.array(aggregatedRegionSchema),
    areas: z.object({
        Asphalt: z.number(),
        Brick: z.number(),
        combined: z.number(),
        Cracked: z.number(),
        Cracked_Panel: z.number(),
        Deteriorated_Panel: z.number(),
        Driveway: z.number(),
        DW: z.number(),
        Gravel: z.number(),
        Gutter: z.number(),
        Obstruction: z.number(),
        Overgrown: z.number(),
        Panel: z.number(),
        Stairs: z.number(),
        Total_Deteriorated_Area: z.number(),
    }),
    avg_cross_slope: z.number(),
    avg_run_slope: z.number(),
    avg_width: z.number(),
    detectable_warning: z.string(),
    dw: z.string(),
    folderName: z.string(),
    gpsMultiline: z.array(z.array(z.number()).length(2)),
    material: z.string(),
    measurement_in_regions: z.boolean(),
    media_links: z.object({
        AutoReport: z.string().optional(),
        labelled_img: z.string().optional(),
        pointcloud_las: z.string().optional(),
        raster_downsampled: z.string(),
    }),
    metadata: z.object({
        id: z.number(),
        collector: z.number(),
        date: z.date(),
        location: z.array(z.number()).length(2),
        length: z.number(),
        scale: z.number(),
        folder: z.string(),
    }),
    min_clear_width: z.number(),
    noRamp: z.boolean(),
    panels: z.array(evalPanelSchema),
    process_state: z.object({
        message: z.object({
            key: z.string(),
        }),
        mode: z.string(),
        process_name: z.string(),
        version_date: z.number(),
        version_string: z.string(),
    }),
    raw_scores: z.object({
        cs_score: z.number(),
        rs_score: z.number(),
        width_score: z.number(),
        dw_score: z.number(),
        percent_det: z.number(),
        ramp_check: z.boolean(),
        vd_score: z.number(),
        max_run_slope: z.number(),
        max_cross_slope: z.number(),
    }),
    regions: z.array(evalRegionSchema),
    scale_factor: z.number(),
    stairs: z.boolean(),
    type: z.boolean(),
    scan_type: z.string(),
    scan_subtype: z.string(),
    vd_count: z.number(),
    violations: violationsSchema,
});

export type EvalJson = z.infer<typeof evalJsonSchema>;

export const obstructionSchema = z.object({
    id: z.string(),
    centroid: z.tuple([z.number(), z.number()]),
    labelled_media_link: z.string(),
    max_severity: violationsKeySchema,
    media_link: z.string(),
    scan_id: z.number(),
    type: z.string(),
    symbol_id: z.string().optional(),
    color: z.string().optional(),
    fpv_media: z.string().optional(),
});

export type Obstruction = z.infer<typeof obstructionSchema>;

// map bounds: west, south, east, north (see https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatboundslike)

export type MapBounds = number[];

export const materials = [
    "Asphalt",
    "Brick",
    "Concrete",
    "Cracked",
    "Deteriorated",
    "Driveway",
    "Gravel",
    "Gutter",
    "Obstructed",
    "Stairs",
];

export const manualTagPropertiesSchema = z.object({
    id: z.string(),
    color: z.string(),
    cropped_media: z.string(),
    feature_name: z.string(),
    folder: z.string(),
    fpv_media: z.string().nullable(),
    google_maps: z.string(),
    labelled_img_media: z.string(),
    raster_media: z.string(),
    scan_id: z.number(),
    type: z.string(),
});

export type ManualTagProperties = z.infer<typeof manualTagPropertiesSchema>;

export type Material = (typeof materials)[number];

export type WebMapProgress = "CREATED" | "IN_PROGRESS" | "FAILED";

export interface Webmap {
    id: number;
    arcgisWebmapId: string;
    user: ProjectUser;
    progress: WebMapProgress;
}
