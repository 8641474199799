import React from "react";
import GeoJSON from "geojson";
import {Box, Stack, Typography} from "@mui/material";
import DWMap from "components/Map/DWMap";
import {useProject} from "contexts/ProjectContext";
import {IndividualFeatureProperties, Obstruction, ViolationSeverity} from "react_ct/types";
import GeoJSONLoadingError from "components/LoadingError";
import LoadingScreen from "components/LoadingScreen";
import {colors} from "react_ct/theme";
import ImplementFilterMenu from "./components/FilterMenu";
import ImplementSidebar from "./components/ImplementSidebar";
import ReviewProgramModal from "./components/ReviewProgramModal";
import {useLocation, useParams} from "react-router-dom";
import {type ImplementStrategy, implementStrategyArray} from "../../types";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType, projectKeys} from "queries/queries";
import {apiRequest, apiReverseGeocode} from "react_ct/requests";
import {type FilterLayer} from "components/Map/filterMapHelpers";
import {getOverlappingPointsInFreeSelect} from "components/Map/helpers";
import ChooseDashboardRegion from "./components/ChooseDashboardRegion";
import {DashboardView} from "pages/portal/Home";
import {toProperCase} from "helpers/utils";

export default function ProgramSelectFeatures(): React.ReactElement {
    const projectContext = useProject();
    const {state, pathname} = useLocation();
    const parsedProgramType = pathname.split("/")[pathname.split("/").length - 1].replace(" ", "");
    const programType = parsedProgramType === "triphazard" ? "trip_hazard" : parsedProgramType;
    const params = useParams();
    const implementType: ImplementStrategy = params.type as ImplementStrategy;
    const features: GeoJSON.Feature[] = state?.features ?? [];
    const [programName, setProgramName] = React.useState<string | undefined>(state?.programName ?? undefined);
    const {currentProject: project, projectManualTags} = projectContext;
    const [clickedFeatures, setClickedFeatures] = React.useState<GeoJSON.Feature[]>(features ?? []);
    const [multiSelectedFeatures, setMultiSelectedFeatures] = React.useState<GeoJSON.Feature[]>([]);
    const [freeSelectRange, setFreeSelectRange] = React.useState<GeoJSON.Feature[]>([]);
    const [filteredSeverities, setFilteredSeverities] = React.useState<string[]>(
        (Object.keys(ViolationSeverity) as Array<keyof typeof ViolationSeverity>).map(key => ViolationSeverity[key]),
    );
    const [filteredTags, setFilteredTags] = React.useState<string[]>(projectManualTags?.map(tag => tag.name) ?? []);
    const [openModal, setOpenModal] = React.useState(false);

    const selectedFeatures = React.useMemo(
        () => Array.from(new Set([...clickedFeatures, ...multiSelectedFeatures])),
        [clickedFeatures, multiSelectedFeatures],
    );
    const latestSelectedFeatures = React.useRef<GeoJSON.Feature[]>(selectedFeatures);
    const latestClickedFeatures = React.useRef<GeoJSON.Feature[]>(clickedFeatures);

    const areFeaturesEqual = (featureA: GeoJSON.Feature, featureB: GeoJSON.Feature): boolean =>
        featureA.properties?.id === featureB.properties?.id;

    const setSelectedFeatures = (feature: GeoJSON.Feature): void => {
        setClickedFeatures(prev => {
            const filteredFeatures = prev.filter(p => !areFeaturesEqual(p, feature));
            return filteredFeatures;
        });
        setMultiSelectedFeatures(prev => {
            const filteredFeatures = prev.filter(p => !areFeaturesEqual(p, feature));
            return filteredFeatures;
        });
    };

    React.useEffect(() => {
        if (!filteredTags.length && projectManualTags) {
            setFilteredTags(projectManualTags.map(tag => tag.name));
        }
    }, [projectManualTags]);

    const {
        data: rawFeaturesData,
        error: featuresError,
        isPending: areFeaturesPending,
    } = useQuery({
        queryKey: [
            ...(implementType === "obstruction"
                ? geojsonKeys.obstructionsGeojson(project?.id)
                : geojsonKeys.individualFeaturesGeojson(project?.id)),
            implementType,
        ],
        enabled: !!project,
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
    });

    const {data: savedViews} = useQuery({
        queryKey: projectKeys.projectDashboards(project?.id),
        enabled: !!project,
        queryFn: async () => {
            const res = await apiRequest({path: `project/${project?.id ?? 0}/dashboards`});
            const data: DashboardView[] = res.data;
            return data;
        },
    });

    const featuresData:
        | GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties | Obstruction>
        | undefined = React.useMemo(() => {
        if (!rawFeaturesData) {
            return;
        }
        if (implementType === "obstruction") {
            const obstructionData: GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction> =
                rawFeaturesData?.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction>;
            return {
                ...obstructionData,
                features: obstructionData.features
                    .filter(
                        (feature: GeoJSON.Feature<GeoJSON.Point, Obstruction>) =>
                            feature.properties.type === "Obstruction",
                    )
                    .map((feature: GeoJSON.Feature<GeoJSON.Point, Obstruction>, index: number) => ({
                        ...feature,
                        properties: {
                            ...feature.properties,
                            id: `${feature.properties?.scan_id}_${index}`,
                            color:
                                feature.properties?.max_severity === "extreme"
                                    ? colors.black
                                    : feature.properties?.max_severity === "severe"
                                      ? colors.darkRed
                                      : feature.properties?.max_severity === "moderate"
                                        ? colors.orange
                                        : feature.properties?.max_severity === "minor"
                                          ? colors.yellow
                                          : colors.green,
                            symbol_id: `clear_width${`_${String(feature.properties?.max_severity)}` ?? ""}`,
                        },
                    })),
            } as GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction>;
        } else {
            return {
                ...rawFeaturesData?.geojsonData,
                features: rawFeaturesData?.geojsonData.features
                    // .filter(
                    //     (feature: GeoJSON.Feature, index: number, arr: GeoJSON.Feature[]) =>
                    //         arr.findIndex(f => f.properties?.scan_id === feature.properties?.scan_id) === index,
                    // )
                    .map((feature: GeoJSON.Feature) => ({
                        ...feature,
                        geometry: {
                            ...feature.geometry,
                        },
                        properties: {
                            ...feature.properties,
                            id: `${feature.properties?.scan_id}_${feature.properties?.feature_name}`,
                            color:
                                projectManualTags?.find(
                                    tag => feature.properties?.type.toLowerCase() === tag.name.toLowerCase(),
                                )?.color ?? colors.orange,
                        },
                    })),
            } as GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties>;
        }
    }, [implementType, rawFeaturesData]);

    const addSelectedFeature = async (
        featureToAdd: GeoJSON.Feature,
        setFeatureFunction: React.Dispatch<React.SetStateAction<GeoJSON.Feature[]>>,
    ): Promise<void> => {
        let featureWithAddress: GeoJSON.Feature;
        try {
            featureWithAddress = {
                ...featureToAdd,
                properties: {
                    ...featureToAdd.properties,
                    address: await apiReverseGeocode({
                        x: (featureToAdd.geometry as GeoJSON.Point).coordinates[0],
                        y: (featureToAdd.geometry as GeoJSON.Point).coordinates[1],
                    }),
                },
            };
        } catch (error) {
            console.error(error);
            featureWithAddress = featureToAdd;
        } finally {
            setFeatureFunction(prev => [...prev, featureWithAddress]);
        }
    };

    const removeSelectedFeature = (
        featureToRemove: GeoJSON.Feature,
        setFeatureFunction: React.Dispatch<React.SetStateAction<GeoJSON.Feature[]>>,
    ): void => {
        setFeatureFunction(prev => prev.filter(f => !areFeaturesEqual(featureToRemove, f)));
    };

    const isFeatureSelected = (features: GeoJSON.Feature[], clickedFeature: GeoJSON.Feature): boolean =>
        features.some(selectedFeature => areFeaturesEqual(selectedFeature, clickedFeature));

    const handleMarkerClick = (clickedFeature: GeoJSON.Feature): void => {
        // find the selected feature in the geojson
        const feature = featuresData?.features.find(feat => feat.properties.id === clickedFeature.properties?.id);
        if (feature) {
            const existsInSelection = isFeatureSelected(latestSelectedFeatures.current, feature);
            const existsInClickedSelection = isFeatureSelected(latestClickedFeatures.current, feature);
            if (clickedFeature && !existsInSelection) {
                void addSelectedFeature(feature, setClickedFeatures);
            } else if (clickedFeature && existsInClickedSelection) {
                removeSelectedFeature(feature, setClickedFeatures);
            }
        }
    };

    const handleMultiSelect = (features: Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>>): void => {
        setFreeSelectRange(prev => {
            const filteredFeatures = prev.filter(
                p => !features.some(feature => feature.id === p.id || feature.properties?.id === p.properties?.id),
            );
            const combinedFeatures = [...filteredFeatures, ...features];
            const featuresWithIds = combinedFeatures.map((feature, index) => ({
                ...feature,
                properties: {
                    ...feature.properties,
                    id: index + 1,
                },
            }));
            return featuresWithIds;
        });
    };

    React.useEffect(() => {
        if (freeSelectRange.length) {
            const overlappingPoints = getOverlappingPointsInFreeSelect(
                freeSelectRange,
                featuresData as GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>,
            );
            const filteredPoints = overlappingPoints.filter(point =>
                programType === "trip_hazard"
                    ? filteredTags.map(tag => tag.toLowerCase()).includes(point.properties?.type)
                    : filteredSeverities.includes(point.properties?.max_severity),
            );
            filteredPoints.forEach(point => {
                if (!isFeatureSelected(multiSelectedFeatures, point)) {
                    void addSelectedFeature(point, setMultiSelectedFeatures);
                }
            });
            multiSelectedFeatures.forEach(feature => {
                if (!isFeatureSelected(filteredPoints, feature)) {
                    removeSelectedFeature(feature, setMultiSelectedFeatures);
                }
            });
        }
    }, [freeSelectRange, filteredTags]);

    React.useEffect(() => {
        latestSelectedFeatures.current = selectedFeatures;
    }, [selectedFeatures]);

    React.useEffect(() => {
        latestClickedFeatures.current = clickedFeatures;
    }, [clickedFeatures]);

    const createFilterLayers = (implementType: string): FilterLayer[] | undefined => {
        switch (implementType) {
            case "obstruction":
                return [{id: "max_severity", layers: filteredSeverities}];
            case "triphazard":
                return [{id: "type", layers: filteredTags.map(tag => tag.toLowerCase())}];
            default:
                return undefined;
        }
    };
    return (
        <Box id="page-container" sx={{height: "100vh", width: "100%", position: "relative"}}>
            {!featuresError && implementStrategyArray.includes(implementType) && featuresData?.features.length ? (
                <>
                    <Stack direction="row" sx={{height: "100%"}}>
                        <Box width="67%" height="100%" position="relative">
                            {featuresData?.features.length === 0 && (
                                <Box
                                    width="100%"
                                    height="100%"
                                    zIndex={10}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: "#000000bb",
                                        backdropFilter: "blur(5px)",
                                    }}>
                                    <Typography color="white" fontSize="1.5rem">
                                        No hazards found
                                    </Typography>
                                </Box>
                            )}
                            {implementType === "obstruction" && (
                                <ImplementFilterMenu
                                    filters={filteredSeverities}
                                    setFilters={setFilteredSeverities}
                                    valueLabels={[
                                        {value: "minor", label: '42" - 46"'},
                                        {value: "moderate", label: '36" - 42"'},
                                        {value: "severe", label: '24" - 36"'},
                                        {value: "extreme", label: '0" - 24"'},
                                    ]}
                                />
                            )}
                            {implementType === "triphazard" && projectManualTags && (
                                <ImplementFilterMenu
                                    filters={filteredTags}
                                    setFilters={setFilteredTags}
                                    valueLabels={projectManualTags
                                        .filter(tag => !tag.archived)
                                        .map(tag => ({
                                            value: tag.name,
                                            label: tag.name
                                                .trim()
                                                .split(" ")
                                                .filter(word => word?.length)
                                                .map(word => toProperCase(word))
                                                .join(" "),
                                            color: tag.color,
                                        }))}
                                />
                            )}
                            {savedViews && (
                                <ChooseDashboardRegion {...{savedViews, setFreeSelectRange, freeSelectRange}} />
                            )}
                            <DWMap
                                freeSelectControl
                                {...{freeSelectRange}}
                                data={featuresData}
                                dataId="id"
                                filterLayers={createFilterLayers(implementType)}
                                selectedFeature={selectedFeatures}
                                onMarkerClick={handleMarkerClick}
                                onFreeSelect={handleMultiSelect}
                                zoomOnClick={false}
                            />
                        </Box>
                        <ImplementSidebar {...{selectedFeatures, setOpenModal, setSelectedFeatures}} />
                    </Stack>
                    <ReviewProgramModal
                        programNameValue={programName}
                        programId={state?.programId ?? undefined}
                        {...{openModal, setOpenModal, selectedFeatures, setProgramName, programType}}
                    />
                </>
            ) : (featuresError ?? !project) ? (
                <GeoJSONLoadingError
                    errorMessage={featuresError?.message ?? "No project for this user could be found"}
                />
            ) : !implementStrategyArray.includes(implementType) ? (
                <GeoJSONLoadingError errorMessage={"Could not load strategy"} />
            ) : !featuresData?.features.length && !areFeaturesPending ? (
                <GeoJSONLoadingError errorMessage="No data for this strategy could be found" />
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
