import axios, {AxiosError, type AxiosRequestConfig, type AxiosResponse} from "axios";
import {getCookie, saveCookie} from "./cookies";

// const localApi = "https://localhost:3015";
const localApi = "http://127.0.0.1:3015"; // for local cors issues
const prodApi = "https://app.deepwalkresearch.com/api";

export const DEV = process.env.NODE_ENV === "development";
const PROD_API = process.env.REACT_APP_API === "prod";
export const API_BASE_URL = PROD_API ? prodApi : DEV ? localApi : prodApi;

interface Config extends AxiosRequestConfig {
    path: string;
    useAuth?: boolean;
    adminAuth?: boolean;
}
export const apiRequest = async (config: Config): Promise<AxiosResponse> => {
    const {method = "GET", path, headers = {}, data, useAuth = true, adminAuth = false, ...rest} = config;
    const url = `${API_BASE_URL}/${path}`;

    try {
        if (useAuth) {
            const authToken = getCookie("accessToken");
            if (!authToken) {
                console.warn("No auth token found");
            } else {
                headers.auth = authToken;
            }
        }
        if (adminAuth) {
            // override normal auth token with admin auth token for admin requests when the admin is spoofing a user
            const authToken = getCookie("adminAccessToken");
            if (!authToken) {
                console.warn("No auth token found");
            } else {
                headers.auth = authToken;
            }
        }

        if (window.location.hostname === "localhost") {
            // eslint-disable-next-line no-console
            console.debug(`API REQUEST - ${path}:`, {method, url, headers, data});
        }

        const response = await axios({
            method,
            url,
            data,
            headers,
            ...rest,
        });

        if (response.headers.auth) {
            saveCookie("accessToken", response.headers.auth);
        }

        if (window.location.hostname === "localhost") {
            // eslint-disable-next-line no-console
            console.debug(`API RESPONSE - ${path}:`, response);
        }
        return response;
    } catch (e: unknown) {
        if (e instanceof Error) {
            throw e;
        } else if (e instanceof AxiosError) {
            if (window.location.hostname === "localhost") {
                // eslint-disable-next-line no-console
                console.error(`API ERROR - ${path}:`, e.response?.data);
            }
            throw e;
        } else {
            throw new Error("An unknown error occurred");
        }
    }
};
