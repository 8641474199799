import {colors} from "react_ct/theme";
import {EvalRegion, ManualTag, Obstruction, ReportType, type ViolationSeverity} from "react_ct/types";
import {layers} from "./constants";
import GeoJSON from "geojson";

export type Layer = (typeof layers)[number];

export interface MapData {
    type: Layer;
    collection: GeoJSON.FeatureCollection;
}

export const COLOR_MAP: Record<Layer, string | Record<ViolationSeverity, string>> = {
    "Tree Hazard": colors.green,
    Overhang: colors.darkBlue,
    "Utility Box": colors.darkGray,
    Vertical: colors.orange,
    "Trip Hazard": colors.orange,
    Horizontal: colors.purple,
    Driveway: colors.lightBlue,
    Hazard: colors.blue,
    "Deterioration Layer": "",
    "Cross Slope": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Run Slope": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Curb Ramp": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
    "Midblock Accessibility": {
        minor: colors.yellow,
        moderate: colors.orange,
        severe: colors.red,
        extreme: colors.darkRed,
    },
};

/**
 * Creates an array of formatted data to display on the map
 * @param geojsonData GeoJSON data
 * @param indvFeatureData Individual feature data
 * @returns Formatted data for the map
 */
export const formatData = (
    geojsonData?: GeoJSON.FeatureCollection | null,
    manualTags?: ManualTag[],
): GeoJSON.FeatureCollection<
    GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
    | (ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string})
    | (Obstruction & {layer_type: string; filter_key: string; symbol_id?: string})
> => {
    if (!geojsonData || !manualTags) {
        return {
            type: "FeatureCollection",
            features: [],
        };
    }
    const formattedData: Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            | (ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string})
            | (Obstruction & {layer_type: string; filter_key: string; symbol_id?: string})
        >
    > = [];

    for (const layer of [...layers, ...manualTags.map(tag => tag.name)]) {
        const layerFeatures = getLayerFeatures(
            layer,
            geojsonData,
            manualTags.find(tag => tag.name === layer),
        );
        formattedData.push(...layerFeatures);
    }
    return {
        type: "FeatureCollection",
        features: formattedData,
    };
};

/**
 * Returns a feature collection for the specified layer
 * @param layer The layer to get a feature collection for
 * @param geojsonData GeoJSON data
 * @returns The feature collection for the layer
 */
const getLayerFeatures = (
    layer: Layer,
    geojsonData: GeoJSON.FeatureCollection,
    manualTag?: ManualTag,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        | (ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string})
        | (Obstruction & {layer_type: string; filter_key: string; symbol_id?: string})
    >
> => {
    if (!geojsonData.features) {
        return [];
    }
    switch (layer) {
        case "Census":
            return communityCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Obstructions":
            return obstructionCollection(geojsonData).map((feature, index) => ({
                ...feature,
                id: `${feature.properties?.scan_id}_${index}`,
                properties: {...feature.properties, id: `${feature.properties?.scan_id}_${index}`},
            }));
        case "Cross Slope Panel":
            return crossSlopePanelCollection(geojsonData).map((feature, index) => ({
                ...feature,
                id: `${feature.properties?.scan_id}_${index}`,
                properties: {...feature.properties, id: `${feature.properties?.scan_id}_${index}`},
            }));
        case "Midblock Accessibility":
            return sidewalkCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Curb Ramp":
            return curbRampCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Clear Width":
            return clearWidthCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Cross Slope":
            return crossSlopeCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Run Slope":
            return runSlopeCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        case "Deterioration Layer":
            return deterioratedCollection(geojsonData).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
        default:
            return indvFeatureCollection(layer, geojsonData, manualTag).map(feature => ({
                ...feature,
                properties: {...feature.properties, id: feature.properties?.metadata?.id},
            }));
    }
};

const communityCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> => {
    const layer = "Census";
    return geojsonData.features
        .filter(feature => feature.geometry.type === "Polygon")
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.B01003_001E < 800
                        ? "#00066d"
                        : feature.properties?.B01003_001E < 1500
                          ? "#0042c5"
                          : feature.properties?.B01003_001E < 2000
                            ? "#13aeff"
                            : "#8defff",
                filter_key: `census_${
                    feature.properties?.B01003_001E < 800
                        ? 799
                        : feature.properties?.B01003_001E < 1500
                          ? 1499
                          : feature.properties?.B01003_001E < 2000
                            ? 1999
                            : 2001
                }`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;
};

export function sidewalkCollectionColor(feature: GeoJSON.Feature<GeoJSON.LineString, ReportType>): string {
    switch (feature.properties.accessibility_grade) {
        case 0:
            return colors.black;
        case 1:
            return colors.darkRed;
        case 2:
            return colors.red;
        case 3:
            return colors.orange;
        case 4:
            return colors.yellow;
        default:
            return colors.green;
    }
}

/**
 * Creates a feature collection for the sidewalk accessibility layer
 * @param geojsonData The geojson data
 * @returns The feature collection for the sidewalk accessibility layer
 */
const sidewalkCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> => {
    const layer = "Midblock Accessibility";
    return geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .filter(feature => (feature.properties?.detectable_warning ?? "").toLowerCase() === "no dw necessary")
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.accessibility_grade === 0
                        ? colors.black
                        : feature.properties?.accessibility_grade === 1
                          ? colors.red
                          : feature.properties?.accessibility_grade === 2
                            ? colors.red
                            : feature.properties?.accessibility_grade === 3
                              ? colors.orange
                              : feature.properties?.accessibility_grade === 4
                                ? colors.yellow
                                : colors.green,
                filter_key: `midblock-accessibility-curb-ramp_${feature.properties?.accessibility_grade as number}`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;
};

export function crossSlopeColor(feature: GeoJSON.Feature<GeoJSON.LineString, ReportType>): string {
    const csScore = feature.properties.measurement_averages.cross_slope;
    if (csScore === undefined || csScore === null) {
        return colors.gray;
    } else if (csScore < 5) {
        return colors.green;
    } else if (csScore < 25) {
        return colors.yellow;
    } else if (csScore < 50) {
        return colors.orange;
    } else if (csScore < 100) {
        return colors.red;
    } else {
        return colors.black;
    }
}

const crossSlopeCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> =>
    geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: "Cross Slope",
                color:
                    feature.properties?.cs_score < 5
                        ? colors.green
                        : feature.properties?.cs_score < 25
                          ? colors.yellow
                          : feature.properties?.cs_score < 50
                            ? colors.orange
                            : feature.properties?.cs_score < 100
                              ? colors.red
                              : colors.black,
                filter_key: `cross-slope_${
                    feature.properties?.cs_score < 5
                        ? 5
                        : feature.properties?.cs_score < 25
                          ? 25
                          : feature.properties?.cs_score < 50
                            ? 50
                            : feature.properties?.cs_score < 100
                              ? 100
                              : 101
                }`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;

export function obstructionColor(feature: GeoJSON.Feature<GeoJSON.Point, Obstruction>): string {
    const maxSeverity = feature.properties.max_severity;
    switch (maxSeverity) {
        case "extreme":
            return colors.black;
        case "severe":
            return colors.red;
        case "moderate":
            return colors.orange;
        case "minor":
            return colors.yellow;
        default:
            return colors.green;
    }
}

const obstructionCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<GeoJSON.Feature<GeoJSON.Point, Obstruction & {layer_type: string; symbol_id: string; filter_key: string}>> => {
    const severities = ["extreme", "severe", "moderate", "minor"];
    const obstructions = geojsonData.features
        .filter(
            feature => feature.properties?.layer_type === "Obstructions" && feature.properties?.type === "Obstruction",
        )
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: "Obstructions",
                symbol_id: `clear_width_${String(feature.properties?.max_severity)}`,
                color:
                    feature.properties?.max_severity === "extreme"
                        ? colors.black
                        : feature.properties?.max_severity === "severe"
                          ? colors.red
                          : feature.properties?.max_severity === "moderate"
                            ? colors.orange
                            : feature.properties?.max_severity === "minor"
                              ? colors.yellow
                              : colors.green,
                filter_key: `obstructions_${severities.indexOf(feature.properties?.max_severity) + 1}`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.Point,
            Obstruction & {color: string; layer_type: string; symbol_id: string; filter_key: string}
        >
    >;

    return obstructions;
};

const crossSlopePanelCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.Point,
        Obstruction & {color: string; layer_type: string; symbol_id: string; filter_key: string}
    >
> => {
    const severities = ["extreme", "severe", "moderate", "minor"];
    const crossSlope = geojsonData.features
        .filter(
            feature => feature.properties?.layer_type === "Obstructions" && feature.properties?.type === "Cross Slope",
        )
        .map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                layer_type: "Cross Slope Panel",
                symbol_id: `cross_slope_${String(feature.properties?.max_severity)}`,
                color:
                    feature.properties?.max_severity === "extreme"
                        ? colors.black
                        : feature.properties?.max_severity === "severe"
                          ? colors.red
                          : feature.properties?.max_severity === "moderate"
                            ? colors.orange
                            : feature.properties?.max_severity === "minor"
                              ? colors.yellow
                              : colors.green,
                filter_key: `cross-slope-panel_${severities.indexOf(feature.properties?.max_severity) + 1}`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.Point,
            Obstruction & {color: string; layer_type: string; symbol_id: string; filter_key: string}
        >
    >;

    return crossSlope;
};

export function runSlopeColor(feature: GeoJSON.Feature<GeoJSON.LineString, ReportType>): string {
    const rsScore = feature.properties.measurement_averages.run_slope;

    if (rsScore === null || rsScore === undefined) {
        return colors.gray;
    } else if (rsScore < 5) {
        return colors.green;
    } else if (rsScore < 6) {
        return colors.yellow;
    } else if (rsScore < 7) {
        return colors.orange;
    } else if (rsScore < 8) {
        return colors.red;
    } else {
        return colors.black;
    }
}

/**
 * Creates a feature collection for the score based layers
 * @param layer The layer to create the collection for
 * @param geojsonData The geojson data
 * @returns The feature collection for the layer
 */
const runSlopeCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> =>
    geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: "Run Slope",
                color:
                    feature.properties?.avg_run_slope < 5
                        ? colors.green
                        : feature.properties?.avg_run_slope < 6
                          ? colors.yellow
                          : feature.properties?.avg_run_slope < 7
                            ? colors.orange
                            : feature.properties?.avg_run_slope < 8
                              ? colors.red
                              : colors.black,
                filter_key: `run-slope_${
                    feature.properties?.avg_run_slope < 5
                        ? 5
                        : feature.properties?.avg_run_slope < 6
                          ? 6
                          : feature.properties?.avg_run_slope < 7
                            ? 7
                            : feature.properties?.avg_run_slope < 8
                              ? 8
                              : 9
                }`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;

export function clearWidthColor(feature: GeoJSON.Feature<GeoJSON.LineString, ReportType>): string {
    const cwScore = feature.properties.measurement_averages.width;

    if (cwScore === null || cwScore === undefined) {
        return colors.gray;
    } else if (cwScore < 36) {
        return colors.black;
    } else if (cwScore < 44) {
        return colors.red;
    } else if (cwScore < 50) {
        return colors.blue;
    } else if (cwScore < 62) {
        return colors.green;
    } else {
        return colors.pink;
    }
}

const clearWidthCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> =>
    geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: "Clear Width",
                color:
                    feature.properties?.avg_width < 36
                        ? colors.black
                        : feature.properties?.avg_width < 44
                          ? colors.red
                          : feature.properties?.avg_width < 50
                            ? colors.blue
                            : feature.properties?.avg_width < 62
                              ? colors.green
                              : colors.pink,
                filter_key: `clear-width_${
                    feature.properties?.avg_width < 36
                        ? 36
                        : feature.properties?.avg_width < 44
                          ? 44
                          : feature.properties?.avg_width < 50
                            ? 50
                            : feature.properties?.avg_width < 62
                              ? 62
                              : 63
                }`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;

/**
 * Creates a feature collection for the curb ramp layer
 * @param geojsonData The geojson data
 * @returns The feature collection for the curb ramp layer
 */
const curbRampCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> => {
    const layer = "Curb Ramp";
    return geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .filter(feature => feature.properties?.detectable_warning !== "No DW Necessary")
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: layer,
                color:
                    feature.properties?.accessibility_grade === 0
                        ? colors.black
                        : feature.properties?.accessibility_grade === 1
                          ? colors.darkRed
                          : feature.properties?.accessibility_grade === 2
                            ? colors.red
                            : feature.properties?.accessibility_grade === 3
                              ? colors.orange
                              : feature.properties?.accessibility_grade === 4
                                ? colors.yellow
                                : colors.green,
                filter_key: `midblock-accessibility-curb-ramp_${feature.properties?.accessibility_grade as number}`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;
};

export function deteriorationColor(feature: GeoJSON.Feature<GeoJSON.LineString, ReportType>): string {
    const percentDeteriorated = feature.properties.percent_deteriorated;
    if (percentDeteriorated === 0) {
        return colors.green;
    } else if (percentDeteriorated < 10) {
        return colors.yellow;
    } else if (percentDeteriorated < 25) {
        return colors.orange;
    } else if (percentDeteriorated < 50) {
        return colors.red;
    } else {
        return colors.black;
    }
}

const deterioratedCollection = (
    geojsonData: GeoJSON.FeatureCollection,
): Array<
    GeoJSON.Feature<
        GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
        ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
    >
> =>
    geojsonData.features
        .filter(feature => feature.properties?.id ?? feature.properties?.metadata?.id)
        .map(feature => ({
            ...feature,
            id: feature.properties?.metadata?.id,
            properties: {
                ...feature.properties,
                layer_type: "Deterioration Layer",
                color:
                    feature.properties?.percent_deteriorated === 0
                        ? colors.green
                        : feature.properties?.percent_deteriorated < 10
                          ? colors.yellow
                          : feature.properties?.percent_deteriorated < 25
                            ? colors.orange
                            : feature.properties?.percent_deteriorated < 50
                              ? colors.red
                              : colors.black,
                filter_key: `deterioration-layer_${
                    feature.properties?.percent_deteriorated === 0
                        ? 0
                        : feature.properties?.percent_deteriorated < 10
                          ? 10
                          : feature.properties?.percent_deteriorated < 25
                            ? 25
                            : feature.properties?.percent_deteriorated < 50
                              ? 50
                              : 51
                }`,
            },
        })) as Array<
        GeoJSON.Feature<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            ReportType & {id: number; color: string; layer_type: string; filter_key: string; symbol_id?: string}
        >
    >;

/**
 * Creates a feature collection for the individual feature data
 * @param layer Layer information
 * @param indvFeatureData The individual feature data
 * @returns The feature collection for the layer
 */
const indvFeatureCollection = (
    layer: string,
    indvFeatureData: GeoJSON.FeatureCollection,
    manualTagData?: ManualTag,
): Array<GeoJSON.Feature<GeoJSON.Point, ReportType & {color: string; layer_type: string; filter_key: string}>> => {
    const manualTagFeatures: Array<
        GeoJSON.Feature<GeoJSON.Point, ReportType & {color: string; layer_type: string; filter_key: string}>
    > = [];

    indvFeatureData.features.forEach(feature => {
        const tagArray: Array<
            GeoJSON.Feature<GeoJSON.Point, ReportType & {color: string; layer_type: string; filter_key: string}>
        > = feature.properties?.regions
            ?.filter((region: EvalRegion) => region.shape_type === "point")
            .map((region: EvalRegion) => ({
                type: "Feature",
                id: feature.properties?.metadata?.id,
                geometry: {
                    type: "Point",
                    coordinates: [region.gps_point[1], region.gps_point[0]] as GeoJSON.Position,
                },
                properties: {
                    ...feature.properties,
                    color: manualTagData?.color ?? "#000",
                    layer_type: layer,
                    filter_key: `${layer.toLowerCase().replaceAll(" ", "-")}_0`,
                },
            }));
        manualTagFeatures.push(...(tagArray ?? []));
    });
    return manualTagFeatures;
};
