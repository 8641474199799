import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip, {chipClasses} from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {colors} from "react_ct/theme";
import type {ImplementProgramFeature} from "react_ct/types";
import {IconButton, Tooltip, darken, rgbToHex} from "@mui/material";
import {useProject} from "contexts/ProjectContext";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import {Check, Close} from "@mui/icons-material";
import {WorkOrderGeojsonFeature} from "../../select/components/constants";

interface AddressListProps {
    features: WorkOrderGeojsonFeature[];
    selectedFeature: WorkOrderGeojsonFeature | undefined;
    setSelectedFeature: (feature: WorkOrderGeojsonFeature | undefined) => void;
    acceptedFeatureIds: number[];
    rejectedFeatureIds: number[];
    setViewingImageIndex: React.Dispatch<React.SetStateAction<number>>;
    acceptProgramFeature: () => void;
    rejectProgramFeature: () => void;
}

export default function AddressList({
    features,
    selectedFeature,
    setSelectedFeature,
    acceptedFeatureIds,
    rejectedFeatureIds,
    setViewingImageIndex,
    acceptProgramFeature,
    rejectProgramFeature,
}: AddressListProps): React.ReactElement {
    const addressListRef = React.useRef<HTMLElement>();
    const {projectManualTags} = useProject();

    React.useEffect(() => {
        // change the scroll position of the address bar when the selected feature is changed
        setViewingImageIndex(0);
        if (selectedFeature) {
            const featureToScrollTo = document.querySelector(`#feature-${selectedFeature.properties.id}`);
            if (featureToScrollTo) {
                featureToScrollTo.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [selectedFeature]);

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}>
            <Box
                ref={addressListRef}
                role="group"
                sx={{
                    maxHeight: "calc(60vh - (0.875rem + 12px))",
                    overflow: "auto",
                    borderRadius: theme => theme.shape.borderRadius,
                    boxShadow: theme => theme.shadows[2],
                    backgroundColor: theme => theme.palette.background.paper,
                }}>
                {features.map(feature => {
                    const featureColor =
                        feature.properties.max_severity === "extreme"
                            ? colors.black
                            : feature.properties.max_severity === "severe"
                              ? colors.darkRed
                              : feature.properties.max_severity === "moderate"
                                ? rgbToHex(darken(colors.orange, 0.2))
                                : feature.properties.max_severity === "minor"
                                  ? rgbToHex(darken(colors.yellow, 0.2))
                                  : colors.green;

                    const manualTagColor = projectManualTags?.find(
                        tag => tag.name.toLowerCase() === feature.properties.type.toLowerCase(),
                    )?.color;

                    const isFeatureSelected = feature.properties.id === selectedFeature?.properties.id;

                    return (
                        <Box
                            p={1}
                            key={feature.id}
                            id={`feature-${feature.id}`}
                            sx={{
                                cursor: "pointer",
                                backgroundColor: acceptedFeatureIds.includes(feature.properties.id)
                                    ? colors.green + "55"
                                    : rejectedFeatureIds.includes(feature.properties.id)
                                      ? colors.red + "55"
                                      : "transparent",
                                "&:hover": {
                                    backgroundColor: theme =>
                                        acceptedFeatureIds.includes(feature.properties.id)
                                            ? colors.green + "77"
                                            : rejectedFeatureIds.includes(feature.properties.id)
                                              ? colors.red + "77"
                                              : theme.palette.grey[200],
                                },
                            }}>
                            <Stack
                                component="button"
                                direction="row"
                                width="100%"
                                border={theme =>
                                    isFeatureSelected ? `1px solid ${theme.palette.midnightBlue.dark + "66"}` : "none"
                                }
                                p={1}
                                borderRadius={theme => theme.shape.borderRadius / 2}
                                alignItems="center"
                                gap={2}
                                boxSizing="border-box"
                                sx={{backgroundColor: "transparent"}}
                                onClick={() => {
                                    setSelectedFeature(feature);
                                }}>
                                <Chip
                                    label={
                                        feature.properties.max_severity
                                            ? toProperCase(feature.properties.max_severity)
                                            : capitalizeFirstLetterOfEachWord(feature.properties.type)
                                    }
                                    sx={
                                        manualTagColor
                                            ? {
                                                  [`&.${chipClasses.root}`]: {
                                                      backgroundColor: manualTagColor + "33",
                                                      color: darken(manualTagColor, 0.4),
                                                  },
                                              }
                                            : feature.properties.max_severity
                                              ? {
                                                    [`&.${chipClasses.root}`]: {
                                                        backgroundColor: featureColor + "33",
                                                        color: featureColor,
                                                    },
                                                }
                                              : undefined
                                    }
                                />
                                <Typography
                                    flex={1}
                                    sx={{
                                        lineHeight: "1.5rem",
                                        maxHeight: "1.5rem",
                                        minWidth: "33%",
                                        textOverflow: "ellipsis",
                                        overflow: "clip",
                                        whiteSpace: "nowrap",
                                        textWrap: "nowrap",
                                    }}>
                                    {feature.properties.address ??
                                        `(${feature.geometry.coordinates[0]}, ${feature.geometry.coordinates[1]})`}
                                </Typography>
                                {isFeatureSelected && (
                                    <Stack direction="row">
                                        <Tooltip title="Accept feature into program">
                                            <IconButton onClick={acceptProgramFeature}>
                                                <Check color="success" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reject feature from program">
                                            <IconButton onClick={rejectProgramFeature}>
                                                <Close color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )}
                            </Stack>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
