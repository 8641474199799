import {ChevronRight, ExpandMore, FolderOpen} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import {
    sidewalkCollectionColor,
    type Layer,
    runSlopeColor,
    crossSlopeColor,
    clearWidthColor,
    deteriorationColor,
    obstructionColor,
} from "components/Map/createDataHelpers";
import React from "react";
import {useProject} from "../../../contexts/ProjectContext";
import {useArray} from "../../../hooks/useArray";
import DownloadJSONs from "./components/Overview/DownloadJSONs";
import {FeatureLegend} from "./components/Overview/FeatureLegend";
import {FilterMenu} from "./components/Overview/FilterMenu";
import ImageCarousel from "./components/Overview/ImageCarousel";
import {type DownloadURL, legendItems} from "./components/Overview/helpers";
import DWMap from "components/Map/DWMap";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getCommunityGeojsonData, getGeojsonByType} from "queries/queries";
import {ManualTagProperties, Obstruction, ReportType} from "react_ct/types";
import {capitalizeFirstLetterOfEachWord} from "helpers/utils";
import {TableLegendComponent} from "./components/Details/TableLegend";
import GeoJSON from "geojson";

function DrawerTable({data}: {data: object}): React.ReactElement {
    if (!data) {
        return <></>;
    }
    return (
        <>
            {Object.entries(data)
                .filter(([, val]) => !!val)
                .map(([key, value]) => {
                    const keyTitle = capitalizeFirstLetterOfEachWord(key.replaceAll("_", " "));
                    if (typeof value === "object") {
                        return (
                            <Accordion
                                key={key}
                                disableGutters
                                sx={{
                                    maxWidth: "22vw",
                                    boxShadow: "none",
                                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                                }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography fontWeight="bold">{keyTitle}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box width="90%" margin="auto">
                                        <DrawerTable data={value} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        );
                    } else {
                        return (
                            <TableRow key={key}>
                                <TableCell>
                                    <Typography fontWeight="bold">{keyTitle}</Typography>
                                </TableCell>
                                <TableCell>
                                    {String(typeof value === "number" && key !== "id" ? value.toFixed(1) : value)}
                                </TableCell>
                            </TableRow>
                        );
                    }
                })}
        </>
    );
}

type IncludedData = GeoJSON.FeatureCollection<
    GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
    (ReportType & {id: number; color: string; filter_type: string}) | (Obstruction | ManualTagProperties)
>;

export function DataOverviewPage(): React.ReactElement {
    const projectContext = useProject();

    const {currentProject: project, projectManualTags} = projectContext;

    const [downloadUrls, setDownloadUrls] = React.useState<DownloadURL>({
        geojson: {
            fileName: undefined,
            url: undefined,
        },
        indvData: {
            fileName: undefined,
            url: undefined,
        },
        obsData: {
            fileName: undefined,
            url: undefined,
        },
        communityData: {
            fileName: undefined,
            url: undefined,
        },
    });

    const [openDetailDrawer, setOpenDetailDrawer] = React.useState<boolean>(false);
    const [selectedFeature, setSelectedFeature] =
        React.useState<
            GeoJSON.Feature<
                GeoJSON.Point | GeoJSON.LineString | GeoJSON.Polygon,
                | (ReportType & {color: string; id: number; filter_type: string})
                | (Obstruction & {symbol_id?: string})
                | ManualTagProperties
            >
        >();
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);

    const [visibleScanLayers, setVisibleScanLayers] = useArray<Layer>(["Midblock Accessibility", "Curb Ramp"]);
    const [visibleScanTypeLayers, setVisibleScanTypeLayers] = useArray<Layer>([]);
    const [visiblePanelLayers, setVisiblePanelLayers] = useArray<Layer>([]);
    const [visibleManualLayers, setVisibleManualLayers] = useArray<Layer>([]);
    const [visiblePopulationLayers, setVisiblePopulationLayers] = useArray<Layer>([]);
    const [selectedValues, setSelectedValues] = React.useState<Array<{key: string; filterValues: number[]}>>([]);

    const visibleLayers = React.useMemo(() => {
        const combinedLayers = [
            ...visibleScanLayers,
            ...visiblePanelLayers,
            ...visibleManualLayers,
            ...visiblePopulationLayers,
        ];
        return combinedLayers;
    }, [visibleScanLayers, visiblePanelLayers, visibleManualLayers, visiblePopulationLayers]);

    const includedDataRef = React.useRef<IncludedData>();

    const nameToKeyLayer = (name: string): string => {
        if (name.toLowerCase() === "midblock accessibility" || name.toLowerCase() === "curb ramp") {
            return "sidewalk";
        }
        return name.toLowerCase().replaceAll(" ", "-");
    };

    React.useEffect(() => {
        // when visible layers changes, update the selected filter values
        const visibleLayerKeys: string[] = [...new Set(visibleLayers.map(layer => nameToKeyLayer(layer)))];

        // remove values that are not in the list of visible layers
        const selectedValuesToKeep = selectedValues.filter(value => visibleLayerKeys.includes(value.key));

        visibleLayerKeys.forEach(layerKey => {
            // check if the layer's values already exist in the filter values array
            const selectedValueKeys = selectedValues.map(value => value.key);
            if (!selectedValueKeys.includes(layerKey)) {
                // if this is a new visible layer, add all of its values to the selected values array
                const legendItemKeys = legendItems.map(item => item.layerKey);
                if (legendItemKeys.includes(layerKey)) {
                    const legendItem = legendItems.find(item => item.layerKey === layerKey);
                    if (legendItem) {
                        const filterValues = legendItem?.values;
                        if (legendItem.values.length < legendItem?.gradient.length) {
                            const extraValue = legendItem.values[legendItem.values.length - 1] + 1;
                            filterValues.push(extraValue);
                        }
                        selectedValuesToKeep.push({key: layerKey, filterValues});
                    }
                } else {
                    selectedValuesToKeep.push({key: layerKey, filterValues: [0]});
                }
            }
        });

        setSelectedValues(selectedValuesToKeep);
    }, [visibleLayers]);

    const {data: geojsonData} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: arcgisData} = useQuery({
        queryKey: geojsonKeys.arcgisGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: indvData} = useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: obsData} = useQuery({
        queryKey: geojsonKeys.obstructionsGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: communityData} = useQuery({
        queryKey: geojsonKeys.communityGeojson(project?.id),
        queryFn: async () => await getCommunityGeojsonData(project),
        enabled: !!project,
    });

    const scanData: Array<
        GeoJSON.Feature<GeoJSON.LineString, ReportType & {id: number; color: string; filter_type: string}>
    > = React.useMemo(() => {
        if (!geojsonData?.geojsonData || !visibleScanLayers.length) {
            return [];
        }
        const geojsonScanData: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType> =
            geojsonData.geojsonData as GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType>;
        const filteredScanData =
            visibleScanLayers.includes("Curb Ramp") && !visibleScanLayers.includes("Midblock Accessibility")
                ? {
                      ...geojsonScanData,
                      features: geojsonScanData.features.filter(
                          feature => feature.properties.detectable_warning.toLowerCase() !== "no dw necessary",
                      ),
                  }
                : geojsonScanData;
        return filteredScanData.features.map(feature => {
            const color =
                visibleScanLayers.includes("Curb Ramp") || visibleScanLayers.includes("Midblock Accessibility")
                    ? sidewalkCollectionColor(feature)
                    : visibleScanLayers.includes("Run Slope")
                      ? runSlopeColor(feature)
                      : visibleScanLayers.includes("Cross Slope")
                        ? crossSlopeColor(feature)
                        : visibleScanLayers.includes("Clear Width")
                          ? clearWidthColor(feature)
                          : deteriorationColor(feature);
            return {
                ...feature,
                id: feature.properties.metadata.id,
                properties: {
                    ...feature.properties,
                    id: feature.properties.metadata.id,
                    color,
                    filter_type:
                        visibleScanLayers.includes("Curb Ramp") || visibleScanLayers.includes("Midblock Accessibility")
                            ? "Sidewalk"
                            : visibleScanLayers[0],
                },
            };
        });
    }, [geojsonData, visibleScanLayers]);

    const selectedScanData = React.useMemo(() => {
        if (!scanData.length) {
            return [];
        }
        const filterType = scanData[0].properties.filter_type;
        return scanData.filter(feature => {
            if (filterType === "Sidewalk") {
                const legendValue = selectedValues.find(val => val.key === filterType.toLowerCase());
                if (!legendValue) {
                    return true;
                }
                return legendValue.filterValues.includes(feature.properties.accessibility_grade);
            } else if (filterType === "Run Slope") {
                const rsScore = feature.properties.measurement_averages.run_slope ?? 9;
                const legendValue = selectedValues.find(
                    val => val.key === filterType.replaceAll(" ", "-").toLowerCase(),
                );
                if (!legendValue) {
                    return true;
                }
                return legendValue.filterValues.includes(
                    Math.ceil(rsScore <= 5 ? 5 : rsScore <= 6 ? 6 : rsScore <= 7 ? 7 : rsScore <= 8 ? 8 : 9),
                );
            } else if (filterType === "Cross Slope") {
                const csScore = feature.properties.measurement_averages.cross_slope ?? 101;
                const legendValue = selectedValues.find(
                    val => val.key === filterType.replaceAll(" ", "-").toLowerCase(),
                );
                if (!legendValue) {
                    return true;
                }
                return legendValue.filterValues.includes(
                    Math.ceil(csScore <= 5 ? 5 : csScore <= 25 ? 25 : csScore <= 50 ? 50 : csScore <= 100 ? 100 : 101),
                );
            } else if (filterType === "Clear Width") {
                const cwScore = feature.properties.measurement_averages.width ?? 63;
                const legendValue = selectedValues.find(
                    val => val.key === filterType.replaceAll(" ", "-").toLowerCase(),
                );
                if (!legendValue) {
                    return true;
                }
                return legendValue.filterValues.includes(
                    Math.ceil(cwScore <= 36 ? 36 : cwScore <= 44 ? 44 : cwScore <= 50 ? 50 : cwScore <= 62 ? 62 : 63),
                );
            } else if (filterType === "Deterioration Layer") {
                const percentDet = feature.properties.percent_deteriorated ?? 51;
                const legendValue = selectedValues.find(
                    val => val.key === filterType.replaceAll(" ", "-").toLowerCase(),
                );
                if (!legendValue) {
                    return true;
                }
                return legendValue.filterValues.includes(
                    Math.ceil(
                        percentDet === 0
                            ? 0
                            : percentDet <= 10
                              ? 10
                              : percentDet <= 25
                                ? 25
                                : percentDet <= 50
                                  ? 50
                                  : 51,
                    ),
                );
            } else {
                return true;
            }
        });
    }, [scanData, selectedValues]);

    const manualTagData: Array<GeoJSON.Feature<GeoJSON.Point, ManualTagProperties>> = React.useMemo(() => {
        if (!indvData?.geojsonData || !visibleManualLayers.length) {
            return [];
        }
        const geojsonManualTagData: GeoJSON.FeatureCollection<GeoJSON.Point, ManualTagProperties> =
            indvData.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, ManualTagProperties>;
        const filteredManualTagData = geojsonManualTagData.features.filter(feature => {
            const matchedTags = projectManualTags?.filter(
                mt => mt.name.toLowerCase() === feature.properties.type.toLowerCase(),
            );
            return !!matchedTags?.length;
        });
        const filteredManualTagFeatures = filteredManualTagData
            .filter(feature => visibleManualLayers.map(mt => mt.toLowerCase()).includes(feature.properties.type))
            .map(feature => {
                const correspondingManualTag = projectManualTags?.find(
                    mt => mt.name.toLowerCase() === feature.properties.type.toLowerCase(),
                );
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        id: `${feature.properties.scan_id}_${feature.properties.feature_name}`,
                        color: correspondingManualTag?.color ?? "#000",
                    },
                };
            });
        return filteredManualTagFeatures;
    }, [indvData, visibleManualLayers]);

    const obstructionData: Array<GeoJSON.Feature<GeoJSON.Point, Obstruction & {symbol_id: string}>> =
        React.useMemo(() => {
            if (!obsData?.geojsonData || !visiblePanelLayers.length) {
                return [];
            }
            const geojsonObsData = obsData.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction>;
            return geojsonObsData.features
                .filter(feature =>
                    visiblePanelLayers.includes(
                        feature.properties.type === "Obstruction"
                            ? "Obstructions"
                            : feature.properties.type === "Cross Slope"
                              ? "Cross Slope Panel"
                              : feature.properties.type,
                    ),
                )
                .map((feature, index) => ({
                    ...feature,
                    id: `${feature.properties.scan_id}_${index}`,
                    properties: {
                        ...feature.properties,
                        id: `${feature.properties.scan_id}_${index}`,
                        color: obstructionColor(feature),
                        symbol_id:
                            feature.properties.type === "Obstruction"
                                ? `clear_width_${String(feature.properties.max_severity)}`
                                : `cross_slope_${String(feature.properties.max_severity)}`,
                    },
                }));
        }, [obsData, visiblePanelLayers]);

    const filteredObsData = React.useMemo(() => {
        const severities = ["extreme", "severe", "moderate", "minor"];
        const obsSeverities = [24, 36, 42, 46];
        const csSeverities = [6, 4, 3, 2.5];

        const filterObstructions = obstructionData.filter(feature => {
            if (feature.properties.type === "Cross Slope") {
                return true;
            }
            const legendValue = selectedValues.find(val => val.key === "obstructions");
            if (!legendValue) {
                return false;
            }
            const severityIndex = severities.indexOf(feature.properties.max_severity);
            return legendValue.filterValues.includes(obsSeverities[severityIndex]);
        });

        const filterData = filterObstructions.filter(feature => {
            if (feature.properties.type === "Obstruction") {
                return true;
            }
            const legendValue = selectedValues.find(val => val.key === "cross-slope-panel");
            if (!legendValue) {
                return false;
            }
            const severityIndex = severities.indexOf(feature.properties.max_severity);
            return legendValue.filterValues.includes(csSeverities[severityIndex]);
        });

        return filterData;
    }, [obstructionData, selectedValues]);

    const includedData: IncludedData = React.useMemo(() => {
        const collection: GeoJSON.FeatureCollection<
            GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon,
            (ReportType & {id: number; color: string; filter_type: string}) | (Obstruction | ManualTagProperties)
        > = {
            type: "FeatureCollection",
            features: [...selectedScanData, ...manualTagData, ...filteredObsData],
        };
        return collection;
    }, [selectedScanData, manualTagData, filteredObsData]);

    React.useEffect(() => {
        includedDataRef.current = includedData;
    }, [includedData]);

    const featureLegendLayer = [
        ...visibleScanLayers.filter(layer => layer !== "Obstructions"),
        ...visiblePanelLayers,
        ...visiblePopulationLayers,
    ];

    const noDataAvailable = ![
        ...(geojsonData?.geojsonData.features ?? []),
        ...(indvData?.geojsonData.features ?? []),
        ...(obsData?.geojsonData.features ?? []),
    ].length;

    const handleOpenDrawer = (): void => {
        setOpenDetailDrawer(true);
    };

    const handleCloseDrawer = (): void => {
        setOpenDetailDrawer(false);
        setSelectedFeature(undefined);
    };

    React.useEffect(() => {
        if (selectedFeature) {
            handleOpenDrawer();
        }
    }, [selectedFeature]);

    const onMarkerClick = React.useCallback(
        (feature: GeoJSON.Feature): void => {
            if (!includedDataRef?.current) {
                return;
            }
            const foundFeature = includedDataRef?.current?.features.find(
                f => f.properties?.id === feature.properties?.id,
            );
            setSelectedFeature(foundFeature);
            setSelectedImageIndex(0);
        },
        [includedDataRef],
    );

    React.useEffect(() => {
        if (!project) {
            return;
        }
        setDownloadUrls({
            geojson: {
                fileName: arcgisData ? `project${project?.id}_arcgis.geojson` : undefined,
                url: arcgisData?.jsonUrl,
            },
            indvData: {
                fileName: indvData ? `project${project?.id}_manual.geojson` : undefined,
                url: indvData?.jsonUrl,
            },
            obsData: {
                fileName: obsData ? `project${project?.id}_obstructions.geojson` : undefined,
                url: obsData?.jsonUrl,
            },
            communityData: {
                fileName: communityData ? `project${project?.id}_community.geojson` : undefined,
                url: communityData?.jsonUrl,
            },
        });
    }, [geojsonData, indvData, obsData, communityData]);

    if (noDataAvailable) {
        return (
            <Box key="page-container" id="page-container" width="100%" height="100vh" position="relative">
                <Box
                    position="absolute"
                    zIndex={10}
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{backgroundColor: "#00000088"}}>
                    <Stack spacing={2} alignItems="center">
                        <FolderOpen
                            sx={{
                                color: "white",
                                fontSize: "5rem",
                            }}
                        />
                        <Typography variant="h3" color={"white"}>
                            No data available
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        <Box key="page-container" id="page-container" width="100%" height="100vh" position="relative">
            <Stack direction="row" spacing={4} position="absolute" zIndex={10} mt={2} ml={4} alignItems="center">
                <FilterMenu
                    visibleLayers={[
                        visibleScanLayers,
                        visiblePanelLayers,
                        visibleManualLayers,
                        visiblePopulationLayers,
                        visibleScanTypeLayers,
                    ]}
                    setVisibleLayers={[
                        setVisibleScanLayers,
                        setVisiblePanelLayers,
                        setVisibleManualLayers,
                        setVisiblePopulationLayers,
                        setVisibleScanTypeLayers,
                    ]}
                />
                <FeatureLegend currentFeatures={featureLegendLayer} {...{selectedValues, setSelectedValues}} />
                <DownloadJSONs downloadUrls={downloadUrls} />
            </Stack>
            <DWMap
                data={includedData}
                dataId={["id", "scan_id", "GEOID"]}
                onMarkerClick={onMarkerClick}
                selectedFeature={selectedFeature}
                satelliteControl={!noDataAvailable}
                annotationControl={false}
            />
            <Drawer
                anchor="right"
                open={openDetailDrawer}
                variant="persistent"
                PaperProps={{
                    sx(theme) {
                        return {
                            zIndex: 14,
                            maxWidth: "25vw",
                            height: "100%",
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                            padding: theme.spacing(2),
                        };
                    },
                    elevation: 2,
                }}>
                {selectedFeature?.properties && (
                    <Stack direction="row" id="sticky-header" justifyContent="space-between" alignItems="center" mb={2}>
                        <IconButton onClick={handleCloseDrawer}>
                            <ChevronRight fontSize="large" />
                        </IconButton>
                    </Stack>
                )}
                {selectedFeature?.properties &&
                    ((selectedFeature.properties as ReportType).media_links ? (
                        <React.Fragment>
                            {Object.entries((selectedFeature.properties as ReportType).media_links).filter(
                                ([key, val]) => val && key !== "autoreport" && key !== "pointcloud_las",
                            ).length > 0 && (
                                <ImageCarousel
                                    imageList={Object.entries(
                                        (selectedFeature.properties as ReportType).media_links,
                                    ).filter(([key, val]) => val && key !== "autoreport" && key !== "pointcloud_las")}
                                    selectedImageIndex={selectedImageIndex}
                                    setSelectedImageIndex={setSelectedImageIndex}
                                />
                            )}
                            <Divider sx={{my: 4}} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {Object.entries(selectedFeature.properties).filter(([key, val]) => key.includes("media"))
                                .length > 0 && (
                                <ImageCarousel
                                    imageList={Object.entries(selectedFeature.properties).filter(([key, val]) =>
                                        key.includes("media"),
                                    )}
                                    {...{selectedImageIndex, setSelectedImageIndex}}
                                />
                            )}
                        </React.Fragment>
                    ))}
                {selectedFeature?.geometry.type !== "Polygon" && (
                    <>
                        <Typography mb={2}>Legend</Typography>
                        <TableLegendComponent evalData={selectedFeature?.properties as ReportType} />
                    </>
                )}
                <Table sx={{mt: 4, maxWidth: "25vw"}}>
                    <TableContainer>
                        {selectedFeature?.properties && (
                            <DrawerTable
                                data={Object.entries(selectedFeature.properties)
                                    .filter(
                                        ([key]) =>
                                            key !== "regions" &&
                                            key !== "aggregated_regions" &&
                                            key !== "layer_type" &&
                                            key !== "color" &&
                                            key !== "filter_key",
                                    )
                                    .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {})}
                            />
                        )}
                    </TableContainer>
                </Table>
            </Drawer>
        </Box>
    );
}
