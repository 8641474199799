import {z} from "zod";

// eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
let projectSchema: z.ZodSchema<any>;
// eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
let organizationSchema: z.ZodSchema<any>;

export enum ProjectUserRole {
    admin = "Admin",
    viewer = "Viewer",
    scanner = "Scanner",
}

export const projectUserSchema = z.object({
    id: z.number(),
    userEmail: z.string(),
    userId: z.number(),
    projects: z.array(z.lazy(() => projectSchema)),
    role: z.nativeEnum(ProjectUserRole),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
});

export type ProjectUser = z.infer<typeof projectUserSchema>;

export enum OrganizationUserRole {
    admin = "Admin",
    superAdmin = "SuperAdmin",
    user = "User",
}

export const organizationUserSchema = z.object({
    id: z.number(),
    userEmail: z.string(),
    userId: z.number(),
    role: z.nativeEnum(OrganizationUserRole),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
});

export type OrganizationUser = z.infer<typeof organizationUserSchema>;

// project

projectSchema = z.object({
    id: z.number(),
    name: z.string(),
    organizations: z.array(z.lazy(() => organizationSchema)), // TODO: fix circular dependency
    members: z.array(projectUserSchema),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
    priority: z.number(),
    projectMileage: z.number(),
    deliveryDate: z.date().nullable(),
});

export interface Project {
    id: number;
    name: string;
    organizations: Organization[];
    members: ProjectUser[];
    createdAt: Date;
    updatedAt: Date;
    priority: number;
    projectMileage: number;
    completed: boolean;
    archived: boolean;
    deliveryDate: string | null;
    approvedForBatchLabeling: boolean;
}

organizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    members: z.array(z.lazy(() => organizationUserSchema)),
    projects: z.array(z.lazy(() => projectSchema)),
    createdAt: z.string().pipe(z.coerce.date()),
    updatedAt: z.string().pipe(z.coerce.date()),
});

export interface Organization {
    id: number;
    name: string;
    members: OrganizationUser[];
    projects: Project[];
    createdAt: Date;
    updatedAt: Date;
}

const detailedProjectMileageSchema = z.object({
    pot_label: z.number(),
    ramp_label: z.number(),
    condition_label: z.number(),
    panel_label: z.number(),
    label_review: z.number(),
    auto_measurements: z.number(),
    measurements_fix: z.number(),
    measurements_fix_ramp: z.number(),
    measurements_fix_review: z.number(),
    report_gen: z.number(),
    passed: z.number(),
    idk: z.number(),
    idk_fix: z.number(),
    rescan: z.number(),
});

export type DetailedProjectMileage = z.infer<typeof detailedProjectMileageSchema>;

// Record of projectId to {count, totalScanLength}
export const projectScanInfoSchema = z.record(
    z.object({
        count: z.number(),
        totalScanLength: z.number(),
    }),
);

export type ProjectScanInfo = z.infer<typeof projectScanInfoSchema>;

// Record of AnnotatorEmail to {Stage to {count, totalScanLength}}
export const projectAnnotatorScanInfoSchema = z.record(
    z.record(
        z.object({
            count: z.number(),
            totalScanLength: z.number(),
        }),
    ),
);

export type ProjectAnnotatorScanInfo = z.infer<typeof projectAnnotatorScanInfoSchema>;

// Geometry schema (simplified, you can expand for more types if needed)
const geometrySchema = z.object({
    type: z.enum(["Point", "LineString", "Polygon", "MultiLineString"]),
    coordinates: z.union([
        z.array(z.number()).length(2),
        z.array(z.array(z.number()).length(2)),
        z.array(z.array(z.array(z.number()).length(2))),
    ]),
});

// Properties schema (can be any object, but you may want to specify your custom structure)
const planPropertiesSchema = z.object({
    db_id: z.number(),
    plan_db_id: z.number(),
});

export type ProjectPlanProperties = z.infer<typeof planPropertiesSchema>;

// Feature schema
const planFeatureSchema = z.object({
    type: z.literal("Feature"),
    geometry: geometrySchema,
    properties: planPropertiesSchema.optional(), // properties can be optional in GeoJSON
    id: z.union([z.string(), z.number()]).optional(), // id can be a string or a number, and is optional
});

// FeatureCollection schema
const planFeatureCollectionSchema = z.object({
    type: z.literal("FeatureCollection"),
    features: z.array(planFeatureSchema),
});

export const projectPlanFeatureSqlSchema = z.object({
    id: z.number(),
    _geometryType: z.string(),
    _coordinates: z.union([
        z.array(z.number()).length(2),
        z.array(z.array(z.number()).length(2)),
        z.array(z.array(z.array(z.number()).length(2))),
    ]),
});

export type ProjectPlanFeatureSql = z.infer<typeof projectPlanFeatureSqlSchema>;

export const projectPlanFeatureSchema = z.object({
    id: z.number().optional(),
    geometryType: z.union([
        z.literal("Point"),
        z.literal("MultiPoint"),
        z.literal("MultiLineString"),
        z.literal("LineString"),
        z.literal("Polygon"),
    ]),
    coordinates: z.union([
        z.array(z.number()).length(2),
        z.array(z.array(z.number()).length(2)),
        z.array(z.array(z.array(z.number()).length(2))),
    ]),
});

export type ProjectPlanFeature = z.infer<typeof projectPlanFeatureSchema>;

export const projectPlanSchema = z.object({
    id: z.number(),
    projectId: z.number(),
    projectUserId: z.number(),
    features: planFeatureCollectionSchema,
    project: projectSchema,
    projectUser: projectUserSchema,
    createdAt: z.date(),
    updatedAt: z.date(),
});

export type ProjectPlan = z.infer<typeof projectPlanSchema>;

export const dashboardFilterSchema = z.object({
    id: z.number(),
    mapRegion: z.array(z.array(z.array(z.number()))),
    featureType: z.array(z.string()),
});

export const scanFilterSchema = z.object({
    id: z.number(),
    accessibilityScores: z.array(z.number().max(5).min(0)).nullable(),
    dwStatus: z.array(z.string()).nullable(),
    itemIDs: z.array(z.number()),
    dashboardFilter: dashboardFilterSchema,
});

export const manualTagFilterSchema = z.object({
    id: z.number(),
    itemIDs: z.array(z.string()),
    manualTags: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            editable: z.boolean(),
            archived: z.boolean(),
        }),
    ),
    dashboardFilter: dashboardFilterSchema,
});

export const individualFeatureFilterSchema = z.object({
    id: z.number(),
    severity: z.array(z.string()),
    type: z.array(z.string()),
    itemIDs: z.array(z.string()),
    dashboardFilter: dashboardFilterSchema,
});

export const projectBudgetItemSchema = z.object({
    id: z.number(),
    name: z.string(),
    quantity: z.number(),
    unitCost: z.number(),
    totalCost: z.number(),
    scanFilter: scanFilterSchema.optional(),
    manualTagFilter: manualTagFilterSchema.optional(),
    individualFeatureFilter: individualFeatureFilterSchema.optional(),
});

export type ProjectBudgetItem = z.infer<typeof projectBudgetItemSchema>;

export const projectBudgetSchema = z.object({
    id: z.number(),
    name: z.string(),
    projectId: z.number(),
    budgetItems: z.array(projectBudgetItemSchema),
});

export type ProjectBudget = z.infer<typeof projectBudgetSchema>;

export {projectSchema, organizationSchema};
